import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Pizza Crust" />
    <h1>My pizza crust recipe</h1>

    <p>It took me a few tries to get this right, but in my opinion, this is really good pizza crust.</p>

    <p><b>Yield:</b> Makes 1 crust</p>

    <h4>Equipment needed</h4>

    <ul>
      <li>Kitchen Aid stand mixer (unless you don't mind kneading by hand)</li>
      <li>Kitchen thermometer</li>
      <li>Pizza stone for baking (recommended)</li>
      <li>Wood pizza peel (recommended)</li>
    </ul>

    <h4>Ingredients</h4>

    <ul>
      <li>1 cup warm water</li>
      <li>2 ¼ tsp active dry yeast</li>
      <li>½ Tbsp sugar</li>
      <li>¾ tsp salt</li>
      <li>2 Tbsp olive oil</li>
      <li>3 cups bread flour (give or take ½ cup)</li>
      <li>Cornmeal (for baking)</li>
    </ul>

    <h4>Instructions</h4>

    <ol>
      <li>Heat 1 cup water for 45 seconds in microwave (or until it reaches 110°)</li>
      <li>In a large mixing bowl, preferably that of a stand mixer, stir yeast and sugar into warm water.
        Sit for 5-10 minutes</li>
      <li>Pour in salt and oil.  Add 1 cup flour and mix. Once that flour is incorporated,
      add 1 more cup until incorporated, mixing with your hands.  Add a little more as needed.
        Dough should be sticky but not stick to your fingers.</li>
      <li>Knead in stand mixer for 6 minutes</li>
      <ul>
        <li>At this point you can freeze your dough if you don't want to use it now!</li>
      </ul>
      <li>Grease bowl with Pam or other oil.  Cover with plastic wrap and let it rise 1-2 hours</li>
      <ul>
        <li>The longer the better for a fully risen crust</li>
        <li>You can also spray Pam inside a plastic bag, place the dough in it, and seal with a twist-tie
          (just like you'd get at the grocery store).</li>
      </ul>
    </ol>

    <h4>Notes:</h4>

    <ul>
      <li>Makes only 1 crust (double/triple if you want more).
        To do multiple doughs, I set up 3 or 4 bowls in assembly-line fashion.</li>
      <li>You don't need to leave this at room temperature; it will rise in the refrigerator as well.</li>
      <li>Bread flour tastes better than all-purpose in my experience.</li>
      <li>Adjust sugar and salt to taste. I like this because it's a good balance between salty
      and sweet. Too much salt and you're grabbing for water in the middle of the night. Too
      sweet and it doesn't pair well with tomato sauce.
      </li>
      <li>Some recipes out there call for adding ¼ tsp garlic powder and ¼ tsp dried basil
      but I found it didn't change the flavor (though it looked appealing).
      </li>
      <li>To bake pizza, put pizza stone in middle rack of oven. Bring to 450°F. Before placing
        pizza in oven, spread 1-2 Tbsp of corneal on the surface of the stone.</li>
    </ul>


  </Layout>
)

export default IndexPage
